.main[data-v-98db15fa] {
  width: 100%;
  height: calc(100vh);
  background-color: #f7f9fc;
  color: #68696b;
}
.main .main-card[data-v-98db15fa] {
    background-color: white;
    -webkit-box-shadow: 2px 4px 12px #edeeef !important;
            box-shadow: 2px 4px 12px #edeeef !important;
    height: 100%;
    overflow: auto;
}
.main .main-card .deal-content[data-v-98db15fa] {
      background-color: #fff;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.main .main-card .deal-content .deal-form[data-v-98db15fa] {
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
}
.main .main-card .deal-content .deal-btns[data-v-98db15fa] {
        position: fixed;
        bottom: 0;
        margin-top: 10px;
        width: 100%;
        height: 50px !important;
        line-height: 50px !important;
        text-align: center;
        background-color: #fff;
}
.main .main-card .deal-content .deal-btns .deal-btn-item[data-v-98db15fa] {
          height: 32px;
          padding: 4px 14px;
          margin-left: 20px;
}
.main .main-card .deal-content .deal-tools[data-v-98db15fa] {
        position: absolute;
        bottom: 39px;
        left: 50px;
        z-index: 2;
        padding: 10px 0;
}
.main .main-card .deal-content .deal-flow[data-v-98db15fa] {
        width: 100%;
        height: 100%;
        outline: none;
}
.main .main-card .deal-logs[data-v-98db15fa] {
      color: black;
      width: 100%;
      height: 100%;
      padding: 10px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #fff;
      border-radius: 3px;
}
.main .main-header[data-v-98db15fa] {
    height: 9.25926vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main .main-header .goToHome[data-v-98db15fa] {
      z-index: 2;
      margin-right: 20px;
      font-weight: bold;
      cursor: pointer;
      fill: #68696b;
      background-color: #d2e7ff;
      padding: 10px;
      color: #000;
      border-radius: 4px;
}
.main .main-header .goToHome[data-v-98db15fa]:hover {
        color: #439dfa;
}
.main .main-header .goToHome[data-v-98db15fa]:hover {
      color: #409eff;
      fill: #409eff;
}
.main .main-content[data-v-98db15fa] {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}
.main .custom-tabs[data-v-98db15fa] .is-active {
    background-color: #fff;
}
.main .custom-tabs[data-v-98db15fa] .el-tabs__item {
    padding: 0 3.333vw;
    font-size: 20px;
    font-weight: 550;
}
.main .custom-tabs[data-v-98db15fa] .el-tabs__nav {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    border-width: 2px;
}
.main .custom-tabs[data-v-98db15fa] .el-tabs__header {
    border-width: 2px;
}
.main .top-button[data-v-98db15fa] {
    position: absolute;
    right: 10px;
    z-index: 2;
    font-weight: bold;
    cursor: pointer;
    color: #000;
    border-radius: 4px;
}
.main .top-button[data-v-98db15fa]:hover {
    color: #409eff;
    fill: #409eff;
}
.change-user-con[data-v-98db15fa] {
  width: 100%;
  height: 450px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.change-user-con .change-user-header[data-v-98db15fa] {
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
}
.change-user-con .change-user-body[data-v-98db15fa] {
    margin-top: 60px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.change-user-con .box-card-alive[data-v-98db15fa] {
    border-color: #409eff;
}
.change-user-con .box-card[data-v-98db15fa] {
    cursor: pointer;
    margin-right: 10px;
    width: 33%;
    height: 300px;
    -webkit-transition: all 0.36s;
    transition: all 0.36s;
}
.change-user-con .box-card[data-v-98db15fa]:hover {
      border-color: #409eff;
}
.change-user-con .box-card[data-v-98db15fa] .el-card__body {
      height: calc(100% - 98px);
}
.change-user-con .box-card .box-header[data-v-98db15fa] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 50%;
}
.change-user-con .box-card .box-header .box-header-left[data-v-98db15fa] {
        width: 50%;
        height: 100%;
}
.change-user-con .box-card .box-header .box-header-left img[data-v-98db15fa] {
          margin: auto;
          width: 80%;
}
.change-user-con .box-card .box-header .box-header-right[data-v-98db15fa] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        height: 100%;
}
.change-user-con .box-card .box-bottom[data-v-98db15fa] {
      width: 100%;
      height: 50%;
}
.change-user-con .box-card .box-list-item[data-v-98db15fa] {
      width: 100%;
      margin-bottom: 10px;
}
[data-v-98db15fa] .el-dialog__footer {
  text-align: center;
}
[data-v-98db15fa] .el-dialog__header {
  text-align: center;
}
[data-v-98db15fa] .el-dialog__header .el-dialog__title {
    font-size: 20px;
}
